import React from 'react'
import './Footer.css'
import footer_logo from '../Assets/logo.png'
import insta_icon from '../Assets/insta.png'
import whatsapp_icon from '../Assets/whatsup.png'

const Footer = () => {
  return (
    <div className='footer'>
        <div className="footer-logo">
            <img src={footer_logo} alt=''/>
            <p>AYURVED HEALTH</p>
        </div>
        <div className="footer-social-icon">
            <div className="footer-icons-container">
                <a href="https://www.instagram.com/ayurved_health0125?igsh=MWQ0b3lreml2bzRndw==" target="_blank" rel="noopener noreferrer">
                <img src={insta_icon} alt=''/>
                </a>
            </div>
            <div className="footer-icons-container">
            <a href="https://wa.me/+918851692499?text=Hi" target="_blank" rel="noopener noreferrer">
                <img src={whatsapp_icon} alt=''/>
                </a>
            </div>
        </div>
        <div className="footer-copyright">
            <hr />
            {/* <p>Copyright 2024 - All Rights Reserved</p> */}
        </div>
    </div>
  )
}

export default Footer
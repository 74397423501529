import React from 'react'
import './Front.css'
// import hand_icon from '../Assets/free.png'
import gain_mix from '../Assets/gain_mix.png'

const Front = () => {
  return (
    <div className='front'>
     <div className="front-left">
        <div className='content'>
            <div>
                <p>TO BUY</p>
            </div>
            <div>
                <p>CALL OR DM NOW</p>
            </div>
            <div className="hand-icon">
                <p>ON +91-8851692499</p>
                {/* <img src={hand_icon} alt=''/> */}
            </div>
            <div>
                <p>Get</p>
            </div>
            <div>
            <p>15% OFF</p>
            </div>
            <div>
            {/* <p>33% Off</p> */}
            </div>
        </div>
     </div>

        <div className="front-right">
            <img src={gain_mix} alt=''/>
        </div>
    </div>
  )
}

export default Front
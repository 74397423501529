const reviewData = [
    {
      name: 'Chandan Kumar',
      rating: 5,
      review: 'I used for 3 months and results are like miracle, Gained 8Kg weight. Awsome product :)',
      image: 'https://via.placeholder.com/80?text=Chandan'
    },
    {
      name: 'Rohit Sharma',
      rating: 5,
      review: 'Awsome product started 20 days ago and gained 3Kg weight',
      image: 'https://via.placeholder.com/80?text=Rohit'
    },
    {
      name: 'Khusi shingh',
      rating: 5,
      review: 'It is just awesome, Changed my looks for my marriage in just 1.5 months.',
      image: 'https://via.placeholder.com/80?text=Khusi'
    },
    {
      name: 'Sourav Yadav',
      rating: 5,
      review: 'Exceptional quality product to loss weight in minimal time',
      image: 'https://via.placeholder.com/80?text=Sourav'
    },
    {
      name: 'Atish Khan',
      rating: 4.5,
      review: 'Nice product',
      image: 'https://via.placeholder.com/80?text=Atish'
    },
    {
      name: 'Rohini singh',
      rating: 5,
      review: 'I have used for only 1 month and loose 5 kg weight, and feeling fit.',
      image: 'https://via.placeholder.com/80?text=Rohini'
    }
];

export default reviewData
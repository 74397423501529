import React, { useContext, useState } from 'react'
import './Item.css'
import { ShopContext } from '../../context/ShopContext'
import Popup from '../Popup/Popup';


const Item = (props) => {
  // const {addToCart} = useContext(ShopContext);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // const handleAddToCart = (product) => {
  //   addToCart(product); // Call the context's addToCart method
  //   setIsPopupOpen(true); // Show the popup after adding to cart
  // };

  // const handleClosePopup = () => {
  //   setIsPopupOpen(false);
  // };
  
  return (
    <div className='item'>
        <div className="main-item">
          <img src={props.img} alt=''/>
          <div className='container'>
            <p>{props.name}</p>
            <div className="item-prices">
              <div className="item-price-new">
                ₹{props.new_price}
              </div>
              <div className="item-price-old">
                ₹{props.old_price}
              </div>
              {/* <button onClick={() => handleAddToCart( props.id)}>Add to Cart</button> */}
              {/* <Popup
                isOpen={isPopupOpen}
                onClose={handleClosePopup}
                title={"ITEM ADDED TO CART"}
              /> */}
            </div>
          </div>
        </div>
        
    </div>
  )
}

export default Item
import p1_img from './product_1.png'
import p2_img from './product_2.png'


let data_product = [
    {
        id:1,
        key:7,
        name:"Ayurved Health Weight Gain Mix",
        image:p1_img,
        new_price:1999.00,
        old_price:2499.00
    },
    {
        id:2,
        key:8,
        name:"Ayurved Health Weight Loss Mix",
        image:p2_img,
        new_price:1999.00,
        old_price:2499.00
    }
];

export default data_product;
import React from "react";
import "./Photos.css";
import photo1 from "../Assets/photo1.png"
import photo2 from "../Assets/photo2.png"
import photo3 from "../Assets/photo3.png"
import photo4 from "../Assets/photo4.png"

const Photos = () => {
    return(
        <div className="image-margin">
            <div className="Images-container1">
                <img src={photo1} alt=''/>
                <img src={photo2} alt=''/>
            </div>
            <div className="Images-container2">
                <img src={photo3} alt=''/>
                <img src={photo4} alt=''/>
            </div>
        </div>
        
    )
}

export default Photos;
import React from 'react';
import './Popup.css';

function Popup({ isOpen, onClose, title }) {
  if (!isOpen){
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>{title}</h3>
        <div className="popup-actions">
          <button onClick={onClose}>Ok</button>
        </div>
      </div>
    </div>
  );
}

export default Popup;
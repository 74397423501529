import React, { useContext, useRef, useState } from 'react'
import './Navbar.css'
import logo from '../Assets/logo.png'
// import cart from '../Assets/cart.png'
// import { Link } from 'react-router-dom'
// import { ShopContext } from '../../context/ShopContext'
// import drop from '../Assets/dropdown.png'

export const Navbar = () => {
    // const [menu,setMenu] = useState("home");
    // const {getTotalCartItems} = useContext(ShopContext);
    // const menuRef = useRef();

    // const dropdown_toggle = (e) =>{
    //     menuRef.current.classList.toggle('nav-menu-visible');
    //     e.target.classList.toggle('open');
    // }

  return (
    <div className='navbar'>
        <div className='nav-logo'>
            <img src={logo} id='logoImg' alt=''/>
            <p>AYURVED HEALTH</p>
        </div>
        {/* <img className='nav-dropdown' onClick={dropdown_toggle} src={drop} alt=''/>
        <ul ref={menuRef} className='nav-menu'>
            <li onClick={()=>{setMenu("home")}}><Link style={{textDecoration:'none'}} to='/'>Home</Link>{menu==="home"?<hr/>:<></>}</li>
            <li onClick={()=>{setMenu("products")}}><Link style={{textDecoration:'none'}} to='/product'>All Products</Link>{menu==="products"?<hr/>:<></>}</li>
            <li onClick={()=>{setMenu("feedbacks")}}><Link style={{textDecoration:'none'}} to='/feedbacks'>Feedback's</Link>{menu==="feedbacks"?<hr/>:<></>}</li>
            <li onClick={()=>{setMenu("contacts")}}><Link style={{textDecoration:'none'}} to='/contacts'>Contact Us</Link>{menu==="contacts"?<hr/>:<></>}</li>
        </ul> */}
        {/* <div className='nav-login-cart'>
            <Link style={{textDecoration:'none'}} to='/cart'>
            <img src={cart} id="cartLogo" alt=''/></Link> 
            <div className="nav-cart-count">{getTotalCartItems()}</div>
        </div> */}


    </div>
  )
}

import './App.css';
import { Navbar } from './Components/Navbar/Navbar';
import { BrowserRouter,Routes,Route} from 'react-router-dom';
import Home from './Pages/Home';
import Feedbacks from './Pages/Feedbacks';
import Contacts from './Pages/Contacts';
import Cart from './Pages/Cart';
import Product from './Pages/Product';
import Footer from './Components/Footer/Footer'; 


function App() {

  return (
    <div className='App'>
      <BrowserRouter>
      <Navbar/>  
      <Routes>
        <Route path='/' element={<Home/>}/>
        {/* <Route path='/feedbacks' element={<Feedbacks/>}/>
        <Route path='/contacts' element={<Contacts/>}/>
        <Route path='/product' element={<Product/>}>
          <Route path=':productId' element={<Product/>}/>
        </Route>
        <Route path='/cart' element={<Cart/>}/> */}
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react'
import './Review.css'
import StarRating from '../StarRating/StarRating';

const Review = ({reviews}) => {
  return (
    <div className='review'>
      <div className='heading'>
        <h1>CUSTOMER REVIEW AND RATINGS</h1>
        <hr/>
      </div>  
      <div className='cards'>
        <div className="reviews-container">
        {reviews.map((review, index) => (
          <div className="review-card" key={index}>
            <img src={review.image} alt={`${review.name}`} className="customer-image" />
            <div className="customer-details">
              <h3 className="customer-name">{review.name}</h3>
              <StarRating rating={review.rating} />
              <p className="customer-review">{review.review}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  )
}

export default Review